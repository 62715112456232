'use client'

import { useCallback, useEffect, useRef, useState } from 'react'

import ToastMessage from 'react-hot-toast'

import CopiedButton from '@/components/CopiedButton'
import LoadingState from '@/components/LoadingState'

import useChatReplicaError from '@/app/[replicaId]/useChatReplicaError'
import Tooltip from '@/components/Tooltip'
import { likeMessage } from '@/server-actions/db'
import { Check, ShareNetwork, SpeakerHigh, StopCircle, ThumbsDown, ThumbsUp } from '@phosphor-icons/react'
import { PauseCircle, PlayCircle } from '@phosphor-icons/react/dist/ssr'
import { useWavesurfer } from '@wavesurfer/react'
import { signIn, useSession } from 'next-auth/react'

const MessageButtons = ({
  messageId,
  elevenlabsId,
  name,
  content,
  isLiked,
  isPersonaTyping,
  like_id,
  replicaId,
}: {
  messageId: string
  elevenlabsId: string
  name: string
  content: string
  isLiked: boolean | null
  isPersonaTyping: boolean
  like_id: string
  replicaId: string
}) => {
  const { setError } = useChatReplicaError()
  const { status } = useSession()
  const containerRef = useRef<HTMLDivElement | null>(null)

  const unauthenticated = status === 'unauthenticated'

  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [messageLiked, setMessageLiked] = useState<boolean | null>(isLiked)
  const [loadingTextToSpeech, setLoadingTextToSpeech] = useState<boolean>(false)
  const [audioUrl, setAudioUrl] = useState<string>()

  const { wavesurfer, isPlaying } = useWavesurfer({
    container: containerRef,
    height: 44,
    barGap: 2,
    barRadius: 4,
    barWidth: 3,
    barHeight: 1,
    waveColor: '#c9b69c',
    progressColor: '#59441b',
    cursorWidth: 0,
    interact: true,
    fillParent: true,
    hideScrollbar: true,
    url: audioUrl,
  })

  const setLiked = async (buttonValue: boolean | null) => {
    if (unauthenticated) {
      signIn()
      return
    }
    try {
      let value: boolean | null
      setMessageLiked((prevState) => {
        if (prevState === buttonValue) {
          value = null
          return null
        }
        value = buttonValue
        return buttonValue
      })

      await likeMessage(value!, messageId, replicaId, like_id)
    } catch (error) {
      setError({ error, friendlyError: 'Something went wrong while liking a chat message.' })
    }
  }

  const shareReplica = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `Check out what ${name} sent to me on Sensay.io`,
          text: `Check out what ${name} sent to me on Sensay.io: ${content}`,
          url: `https://sensay.io/${replicaId}`,
        })
        .catch((error) => console.log(error))
    } else {
      setIsCopied(true)

      navigator.clipboard.writeText(
        `Check out what ${name} sent to me on Sensay.io: ${content} https://sensay.io/${replicaId}`,
      )

      ToastMessage.success('Copied!', {
        position: 'top-center',
        style: {
          padding: '16px',
          color: '#ffffff',
          background: '#FE8F5C',
          paddingBlock: '6px',
        },
        iconTheme: {
          primary: '#ffffff',
          secondary: '#FE8F5C',
        },
      })

      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    }
  }

  const textToSpeech = useCallback(async () => {
    try {
      setLoadingTextToSpeech(true)

      const response = await fetch('/api/text-to-speech', {
        method: 'POST',
        body: JSON.stringify({ replicaId, elevenlabsId, content, saveOnDb: false }),
      })

      const res = await response.blob()

      if (response.ok) {
        setAudioUrl(URL.createObjectURL(res))
        console.log(URL.createObjectURL(res))
      } else {
        const errorRes = await response.json()
        setError({ error: errorRes.error, friendlyError: 'Something went wrong converting text to speech.' })
      }

      setLoadingTextToSpeech(false)
    } catch (error) {
      setError({ error, friendlyError: 'Something went wrong converting text to speech.' })

      setLoadingTextToSpeech(false)
    }
  }, [content, elevenlabsId, replicaId])

  const changePlaybackSpeed = (speedMultiplier: number) => {
    if (!wavesurfer) return

    wavesurfer.setPlaybackRate(speedMultiplier)
  }

  const playPause = useCallback(() => {
    if (!wavesurfer) return

    console.log('isPlaying', isPlaying)
    if (isPlaying) wavesurfer.pause()
    else wavesurfer.play()
  }, [isPlaying, wavesurfer])

  useEffect(() => {
    if (!containerRef.current) return
    if (!wavesurfer) return

    wavesurfer.on('interaction', () => {
      wavesurfer.play()
    })
    wavesurfer.on('finish', () => {
      wavesurfer.stop()
    })

    return () => {
      wavesurfer.destroy()
    }
  }, [wavesurfer])

  return (
    <div className="min-h-7">
      {!isPersonaTyping ? (
        <div className="flex flex-col">
          <div className="relative mt-[6px] flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Tooltip tooltip="Like">
                <button
                  className="cursor-pointer opacity-50 transition-opacity duration-300  hover:opacity-70"
                  onClick={() => setLiked(true)}
                  type="button"
                >
                  <ThumbsUp size={16} weight={messageLiked === true ? 'fill' : 'regular'} />
                </button>
              </Tooltip>

              <Tooltip tooltip="Dislike">
                <button
                  className="cursor-pointer opacity-50 transition-opacity duration-300  hover:opacity-70"
                  onClick={() => setLiked(false)}
                  type="button"
                >
                  <ThumbsDown size={16} weight={messageLiked === false ? 'fill' : 'regular'} />
                </button>
              </Tooltip>

              <Tooltip tooltip="Copy to clipboard">
                <span className="shrink-0 opacity-50">{!isPersonaTyping && <CopiedButton text={content} />}</span>
              </Tooltip>

              <Tooltip tooltip="Share">
                <button
                  className="cursor-pointer opacity-50 transition-opacity duration-300  hover:opacity-70"
                  onClick={shareReplica}
                  type="button"
                >
                  {isCopied ? (
                    <Check className="h-4 w-4 cursor-pointer transition-opacity duration-300 hover:opacity-70" />
                  ) : (
                    <ShareNetwork size={16} />
                  )}
                </button>
              </Tooltip>

              <div className="flex items-center justify-center">
                {elevenlabsId && !audioUrl && (
                  <Tooltip tooltip="Listen to this message">
                    <button
                      onClick={textToSpeech}
                      disabled={loadingTextToSpeech}
                      className="cursor-pointer opacity-50 transition-opacity duration-300 hover:opacity-70"
                      type="button"
                    >
                      {loadingTextToSpeech ? (
                        <LoadingState size={16} />
                      ) : isPlaying ? (
                        <StopCircle size={16} />
                      ) : (
                        <SpeakerHigh size={16} />
                      )}
                    </button>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>

          {elevenlabsId && audioUrl && (
            <div className="mt-3 flex flex-row items-center gap-1 rounded-full border border-primary/30 bg-primary/10 px-3 py-1 text-sm">
              <button className="shrink-0" type="button" onClick={playPause}>
                {isPlaying ? <PauseCircle size={28} /> : <PlayCircle size={28} />}
              </button>

              <div className="ml-1 h-full w-full" ref={containerRef} />

              <select
                className="shrink-0 cursor-pointer appearance-none border-transparent bg-transparent bg-none p-2 text-center outline-0 focus:border-0 focus:outline-0 focus:ring-0"
                onChange={(event) => changePlaybackSpeed(Number.parseFloat(event.target.value))}
              >
                <option value={1.0}>1x</option>
                <option value={1.25}>1.25x</option>
                <option value={1.5}>1.5x</option>
                <option value={1.75}>1.75x</option>
                <option value={2.0}>2x</option>
              </select>
            </div>
          )}
        </div>
      ) : (
        <div className="h-4 w-4" />
      )}
    </div>
  )
}

export default MessageButtons
